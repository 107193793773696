import { useEffect } from 'react'

export function useResize(maxWidth) {
  useEffect(() => {
    const resizeFrame = () => {
      let targetWidth = document.documentElement.clientWidth
      targetWidth = targetWidth > maxWidth ? maxWidth : targetWidth
      const percent = targetWidth / 400
      document.body.style.transform = `scale(${percent})`
      document.querySelector('html').style.height = `${targetWidth}px`
    }

    resizeFrame()
    window.addEventListener('resize', resizeFrame)

    return () => {
      window.removeEventListener('resize', resizeFrame)
    }
  }, [maxWidth])
}