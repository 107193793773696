import { useState, useEffect } from 'react'
import { getPlayListById } from '../api'

export function usePlaylist(defaultPlaylistId) {
  const [playlistId, setPlaylistId] = useState(defaultPlaylistId)
  const [playlist, setPlaylist] = useState([])

  useEffect(() => {
    getPlayListById(playlistId).then(list => {
      setPlaylist(list)
    })
  }, [playlistId])

  return {
    playlistId,
    playlist,
    setPlaylistId,
  }
}
