import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './button.scss'

export function Button({
  className,
  onClick,
  isActive,
  svgSrc,
}) {
  const style = svgSrc === null ? {} : {
    maskImage: `url(${svgSrc})`,
    WebkitMaskImage: `url(${svgSrc})`,
  }

  const classNames = classnames(className, 'button', {
    'is-active': isActive,
  })

  return (
    <button
      className={classNames}
      style={style}
      onClick={onClick}
    />
  )
}

Button.propTypes = {
  className: PropTypes.string,
  onClick: PropTypes.func.isRequired,
  isActive: PropTypes.bool,
  svgSrc: PropTypes.string,
}

Button.defaultProps = {
  className: '',
  isActive: false,
  svgSrc: null,
}
