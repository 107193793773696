import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './play-list.scss'

function Item({
  isActive,
  mId,
  index,
  name,
  singer,
  onClick,
}) {
  return (
    <div
      className={classnames('item', {
        'is-active': isActive
      })}
      onClick={() => onClick(index)}
      key={mId}
    >
      <span className="name">{name}</span>
      <span className="singer">{singer}</span>
    </div>
  )
}

Item.propTypes = {
  isActive: PropTypes.bool.isRequired,
  mId: PropTypes.number.isRequired,
  index: PropTypes.number.isRequired,
  name: PropTypes.string.isRequired,
  singer: PropTypes.string.isRequired,
  onClick: PropTypes.func.isRequired,
}

export function Playlist({
  className,
  playlist,
  nowPlayId,
  onClickItem,
}) {
  const classNames = classnames('m-list', className)

  return (
    <div
      className={classNames}>
      <div className="u-list">
        {
          playlist.map(({ name, singer, mId }, index) => (
            <Item
              key={mId}
              isActive={nowPlayId === mId}
              name={name}
              singer={singer}
              mId={mId}
              index={index}
              onClick={() => onClickItem(index)}
            />
          ))
        }
      </div>
    </div>
  )
}

Playlist.propTypes = {
  className: PropTypes.string,
  playlist: PropTypes.array.isRequired,
  nowPlayId: PropTypes.number.isRequired,
  onClickItem: PropTypes.func.isRequired,
}

Playlist.defaultProps = {
  className: '',
}
