const Host = 'https://static.hduzplus.xyz/musicapi'

export const getPlayListById = (id) => fetch(`${Host}/playlist/detail?id=${id}&timestamp=${Date.now()}`, {
  mode: 'cors'
}).then(res => {
    return res.json()
}).then(data => {
  if (data.code === 200) {
    return data.playlist.tracks
  }

  throw new Error('接口返回异常')
}).then(list => {
  return list.map(item => ({
    name: item.name,
    mId: item.id,
    singer: item.ar[0] && item.ar[0].name,
    pic: item.al.picUrl.replace('http:', 'https:') + '?param=800y800'
  }))
}).catch(() => {
  throw new Error('网络请求失败')
})
