import React, { useState, useEffect, useRef } from 'react'
import classnames from 'classnames'
import { Control } from './components/control'
import { Playlist } from './components/play-list'
import { Mask } from './components/mask'
import { getInitialPlaylistId } from './util'
import { useMusic } from './hooks/use-music'
import { useResize } from './hooks/use-resize'
import './App.scss'

function App() {
  const audioEl = useRef(null)
  const [isPlaying, setIsPlaying] = useState(false)
  const [isRandom, setIsRandom] = useState(false)
  const [isShowPlayList, setIsShowPlayList] = useState(true)
  const [isLocked, setIsLocked] = useState(false)
  const [isMouseEnter, setIsMouseEnter] = useState(false)
  const {
    playlist,
    musicInfo,
    setMusicIndex,
  } = useMusic(getInitialPlaylistId('3224107482'), isRandom)

  useResize(500)

  const [currentDuration, setCurrentDuration] = useState(0)

  const onMouseEnter = () => {
    setIsMouseEnter(true)
  }

  const onMouseLeave = () => {
    if (isLocked) { return }
    setIsMouseEnter(false)
  }

  const play = async () => {
    if (audioEl.current === null) { return }
    try {
      await audioEl.current.play()
      setIsPlaying(true)
    } catch (e) {
      setIsPlaying(false)
    }
  }

  const pause = async () => {
    if (audioEl.current === null) { return }
    try {
      await audioEl.current.pause()
      setIsPlaying(false)
    } catch (e) {
      setIsPlaying(true)
    }
  }

  const playPauseHandler = () => {
    if (isPlaying) {
      pause()
    } else if (musicInfo.mIndex === -1 && playlist.length !== 0) {
      setMusicIndex(0)
    } else if (musicInfo.mIndex !== -1) {
      play()
    }
  }

  const clickPrevNextHandler = (next) => {
    let target

    if (playlist.length === 1) { return }
    if (playlist.length === 2 || !isRandom) { target = musicInfo.mIndex + next }
    else {
      target = musicInfo.mIndex
      while (target === musicInfo.mIndex) {
        target = (musicInfo.mIndex + ~~(Math.random() * 10)) % playlist.length
      }
    }

    setMusicIndex(target)
  }

  const audioErrorHandler = () => {
    pause()
  }

  useEffect(() => {
    const getAudioCurrentTime = () => {
      if (audioEl.current) {
        setCurrentDuration((~~(audioEl.current.currentTime / audioEl.current.duration * 1000)) / 10)
      }
      window.requestAnimationFrame(getAudioCurrentTime)
    }

    getAudioCurrentTime()
  }, [])

  useEffect(() => {
    play()
  }, [musicInfo.mSource])

  return (
    <div
      style={{ backgroundImage: `url(${musicInfo.mPic})` }}
      onMouseEnter={onMouseEnter}
      onMouseLeave={onMouseLeave}
      className={classnames('App', { 'is-active': isMouseEnter })}
    >
      <Mask duration={currentDuration} />
      <Playlist
        className={classnames({ 'is-hidden': !isShowPlayList })}
        playlist={playlist}
        nowPlayId={musicInfo.mId}
        onClickItem={(index) => setMusicIndex(index)}
      />
      <Control
        currentDuration={currentDuration}
        isPlaying={isPlaying}
        isRandom={isRandom}
        isShowPlayList={isShowPlayList}
        isLocked={isLocked}
        onClickRandom={() => setIsRandom(!isRandom)}
        onClickPlayPause={playPauseHandler}
        onClickPrevNext={clickPrevNextHandler}
        onClickShowList={() => setIsShowPlayList(!isShowPlayList)}
        onClickLock={() => setIsLocked(!isLocked)}
      />
      {
        musicInfo.mSource && 
          <audio
            ref={audioEl}
            src={musicInfo.mSource}
            key={musicInfo.mSource}
            onError={audioErrorHandler}
            onEnded={() => clickPrevNextHandler(1)}
          />
      }
    </div>
  )
}

export default App
