import React from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'

import './mask.scss'

export function Mask ({
  className,
  duration,
}) {
  return (
    <div
      className={classnames('m-mask', className)}
      style={{ transform: `translateX(-${100 - duration}%)` }}
    />
  )
}

Mask.propTypes = {
  className: PropTypes.string,
  duration: PropTypes.number,
}

Mask.defaultProps = {
  className: '',
  duration: 0,
}
