import React, { useRef, useEffect, useState } from 'react'
import PropTypes from 'prop-types'
import classnames from 'classnames'
import './control.scss'
import { Button } from './button'

export function Control({
  className,
  isRandom,
  isPlaying,
  isShowPlayList,
  isLocked,
  onClickRandom,
  onClickPrevNext,
  onClickPlayPause,
  onClickShowList,
  onClickLock,
  currentDuration,
}) {
  const canvas = useRef(null)
  const [ctx, setContext] = useState(null)

  const classNames = classnames(className, 'm-control')

  useEffect(() => {
    if (canvas.current === null) { return }

    const ctx = canvas.current.getContext('2d')
    ctx.lineWidth = 40
    ctx.strokeStyle = '#50E3C2'

    setContext(ctx)
  }, [canvas])

  useEffect(() => {
    if (ctx === null) { return }

    const degree = Math.PI * 2.0 * currentDuration / 100
    ctx.clearRect(0, 0, 224, 224)
    ctx.beginPath()
    ctx.arc(112.5, 112.5, 80, -Math.PI / 2, degree - Math.PI / 2, false)
    ctx.stroke()
  }, [currentDuration, ctx])

  return (
    <div className={classNames}>
      <Button
        className="u-random"
        onClick={onClickRandom}
        svgSrc={require('../assets/mode.svg')}
        isActive={isRandom}
      />
      <Button
        className="u-prev"
        onClick={() => onClickPrevNext(-1)}
        svgSrc={require('../assets/prev.svg')}
      />
      <div className="m-play">
        <canvas ref={canvas} width="224" height="224" className="u-progress" />
        <Button
          className={classnames('u-play', { 'is-shown': !isPlaying })}
          onClick={onClickPlayPause}
          svgSrc={require('../assets/play.svg')}
        />
        <Button
          className={classnames('u-pause', { 'is-shown': isPlaying })}
          onClick={onClickPlayPause}
          svgSrc={require('../assets/pause.svg')}
        />
      </div>
      <Button
        className="u-next"
        onClick={() => onClickPrevNext(1)}
        svgSrc={require('../assets/next.svg')}
      />
      <Button
        className="u-list"
        onClick={onClickShowList}
        svgSrc={require('../assets/list.svg')}
        isActive={isShowPlayList}
      />
      <Button
        className="u-lock"
        onClick={onClickLock}
        isActive={isLocked}
      />
    </div>
  )
}

Control.propTypes = {
  className: PropTypes.string,
  isRandom: PropTypes.bool.isRequired,
  isPlaying: PropTypes.bool.isRequired,
  isShowPlayList: PropTypes.bool.isRequired,
  isLocked: PropTypes.bool.isRequired,
  onClickRandom: PropTypes.func.isRequired,
  onClickPrevNext: PropTypes.func.isRequired,
  onClickPlayPause: PropTypes.func.isRequired,
  onClickShowList: PropTypes.func.isRequired,
  onClickLock: PropTypes.func.isRequired,
  currentDuration: PropTypes.number,
}

Control.defaultProps = {
  className: '',
  currentDuration: 0,
  isLocked: false,
}
