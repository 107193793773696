import { useState, useEffect } from 'react'
import { usePlaylist } from './use-playlist'


export function useMusic(defaultPlaylistId) {
  const { playlistId, playlist, setPlaylistId } = usePlaylist(defaultPlaylistId)
  const [musicIndex, setMusicIndex] = useState(-1)
  const [musicInfo, setMusicInfo] = useState({
    mIndex: -1,
    mId: -1,
    mPic: require('../assets/bg.png'),
    mSource: null,
  })

  useEffect(() => {
    if (playlist === null || playlist.length === 0 || musicIndex === -1) { return }
    const index = musicIndex % playlist.length
    const {
      mId,
      pic: mPic,
    } = playlist[index]

    setMusicInfo({
      mIndex: index,
      mId,
      mSource: `https://music.163.com/song/media/outer/url?id=${mId}.mp3`,
      mPic,
    })
  }, [musicIndex, playlist])

  return {
    playlist,
    playlistId,
    musicInfo,
    setPlaylistId,
    setMusicIndex,
  }
}
