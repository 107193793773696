export function getUrlParam (_str, _name) {
  const _reg = new RegExp('(^|&|\\?)' + _name + '=([^&]*)(&|$)'),
      _r = _str.replace(/script|%22|%3E|%3C|'|"|>|<|\\/g,'_').match(_reg)

  if (_r != null) {
    return decodeURIComponent(_r[2])
  }
  return ''
}

export function getCookie (name) {
  const r = new RegExp("(^|;|\\s+)" + name + "=([^;]*)(;|$)")
  const m = document.cookie.match(r)
  return (!m ? '' : unescape(m[2]))
}

export function setCookie (name, v, expire, domain, path) {
  let s = name + "=" + escape(v) + "; path=" + (path || '/') + (domain ? ("; domain=" + domain) : '')
  if (expire > 0) {
    const d = new Date()
    d.setTime(d.getTime() + (+expire || 30) * 24 * 60 * 60 * 1000)
    s += ";expires=" + d.toUTCString()
  }
  document.cookie = s
}

export function getInitialPlaylistId (defaultPid = null) {
  if (getUrlParam(window.location.search, 'pid')) {
    const pid = getUrlParam(window.location.search, 'pid')
    setCookie('pid', pid)
    return pid
  } else if (getCookie('pid')) {
    return getCookie('pid')
  }

  return defaultPid
}
